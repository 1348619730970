import classnames from 'classnames'
import React, { useCallback, useState } from 'react'
import TetherComponent from 'react-tether'
import { CSSTransition } from 'react-transition-group'

import Trans from './trans'

import '../../../scss/react/components/tether.scss'
import '../../../scss/react/components/tooltip.scss'

const isTouchDevice =
    global.document && !!('ontouchstart' in document.documentElement)

interface TooltipProps {
    className?: string
    children?: React.ReactNode
    label: string
    values?: { [key: string]: string | number }
    duration?: number
    timeout?: number
    delay?: number
    dutch?: boolean
}

const Tooltip = ({
    className,
    children,
    label,
    values,
    duration,
    timeout,
    delay,
    ...props
}: TooltipProps) => {
    const [hover, setHover] = useState(false)

    const hoverEnd = useCallback(() => {
        setHover(false)
    }, [])

    const hoverStart = useCallback(() => {
        setHover(true)
    }, [])

    return (
        <TetherComponent
            className="zupr-tooltip-tether"
            renderTarget={(ref) => (
                <span
                    key="tooltip"
                    ref={ref}
                    className={classnames('zupr-tooltip-holder', className)}
                    onMouseLeave={hoverEnd}
                    onMouseEnter={(!isTouchDevice && hoverStart) || null}
                >
                    {children}
                </span>
            )}
            renderElement={(ref) => (
                <CSSTransition
                    in={hover}
                    timeout={{
                        appear: duration,
                        enter: duration,
                        exit: duration,
                    }}
                    appear
                    unmountOnExit
                    classNames="tooltip-transition"
                >
                    <span ref={ref} className="zupr-tooltip">
                        <Trans {...props} label={label} values={values} />
                    </span>
                </CSSTransition>
            )}
            // @ts-ignore
            attachment="bottom center"
            targetAttachment="top center"
            offset="16px 0"
            constraints={[
                {
                    to: 'window',
                    pin: true,
                    attachment: 'together',
                },
            ]}
        />
    )
}

Tooltip.defaultProps = {
    delay: 600,
    duration: 2000,
    values: {},
    timeout: 5000,
}

export default Tooltip
