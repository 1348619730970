import { t } from '@zupr/i18n'
import { Field } from '@zupr/types/form'
import classnames from 'classnames'
import React from 'react'

import { ReactComponent as WarningIcon } from '../../../../svg/warning.svg'
import ErrorBoundary from '../../error/boundary'
import Label, { LabelProps } from './label'

import '../../../../scss/react/form/row.scss'
import Trans from '../../components/trans'

interface RowProps extends LabelProps {
    children: React.ReactNode
    /** @deprecated */
    error?: never
    className?: string
    field?: Field
    label?: string
    valid?: boolean
    /** @deprecated */
    dutch?: never
}

const Row = ({ children, className, error, ...props }: RowProps) => {
    const hasError = !!(error || props.field?.errors)
    return (
        <div
            className={classnames('form-row', className, {
                'form-row-error': hasError,
            })}
        >
            <Label {...props} />
            {props.field?.help && <p>{t(props.field.help)}</p>}
            <ErrorBoundary>{children}</ErrorBoundary>
            <ErrorBoundary>
                {hasError && (
                    <span className="form-row-error-message">
                        <WarningIcon />
                        {props.field?.errors?.map((error) => (
                            <Trans label={error} context="errors" markdown />
                        ))}
                        {!!error && (
                            <Trans label={error} context="errors" markdown />
                        )}
                    </span>
                )}
            </ErrorBoundary>
        </div>
    )
}

export const CombinedFields = ({ children }) => {
    return <div className="combined-form-fields">{children}</div>
}

export const CombinedRows = ({ children }) => {
    return <div className="combined-form-rows">{children}</div>
}

export default Row
